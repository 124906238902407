body {
    margin: 0;
    padding: 0;
  }
  
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .login-form {
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .logo {
    width: 350px;
    margin-bottom: 2rem;
  }
  
  .p-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 1rem;
    text-align: left;
  }
  
  .p-inputtext,
  .p-password input {
    width: 350px;
    height: 50px;
    padding: 0.5rem;
  }
  
  .p-button-primary {
    width: 100%;
    height: 50px;
    background-color: #0075b3;
    border-color: #0075b3;
    padding: 0.5rem;
  }
  
  .error-message {
    color: red;
    margin-bottom: 1rem;
  }